import React from "react";
import Cloud from "../../../assets/img/icons/keySpecs/cloud.svg";
import Configuration from "../../../assets/img/icons/keySpecs/configuration.svg";
import Dual from "../../../assets/img/icons/keySpecs/dual.svg";
import RealTime from "../../../assets/img/icons/keySpecs/real-time.svg";
import Responsive from "../../../assets/img/icons/keySpecs/responsive.svg";
import Speedtest from "../../../assets/img/icons/keySpecs/speedtest.svg";
import Wireguard from "../../../assets/img/icons/keySpecs/wireguard.svg";
import Wpa3 from "../../../assets/img/icons/keySpecs/wpa3.svg";

export default function KeySpecs() {
  const data = {
    title: "Key Specs",
    list: [
      {
        icon: Speedtest,
        name: "Speedtest tool",
      },
      {
        icon: Wpa3,
        name: "WPA3",
      },
      {
        icon: Responsive,
        name: "Responsive UI design",
      },
      {
        icon: RealTime,
        name: "Real-time events and device log in UI",
      },
      {
        icon: Dual,
        name: "Dual firmware",
      },
      {
        icon: Wireguard,
        name: "Wireguard VPN",
      },
      {
        icon: Configuration,
        name: "Single and Simple Configuration File in .json",
      },
      {
        icon: Cloud,
        name: "Cloud Agent",
      },
    ],
  };

  return (
    <div className="section row">
      <div className="col-9 transparent">
        <div className="lg-title">{data.title}</div>
        <div className="row">
          {data.list.map((x, i) => {
            return (
              <div
                className="mb-3 col-xl-3 col-lg-4 col-md-6 col-sm-12"
                key={i}
              >
                <div className="row align-items-center h-100">
                  <div className="col-auto pe-0 py-2">
                    <div className="rounded-circle medium-circle">
                      <img src={x.icon} alt="icon" />
                    </div>
                  </div>
                  <div className="col">
                    <p className="description">{x.name}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
