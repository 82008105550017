import React from "react";
import classNames from "classnames";

export default function ColumnHeader(props) {
  const {
    header,
    columns,
    sort,
    sortOrder,
    sortColumn,
    setSortOrder,
    reduceLastChild,
  } = props;
  const sortable = sort && header.length;
  const hideSort = columns[header]?.hideSort;
  return (
    <th
      className={classNames(
        sort && !hideSort ? "sort" : null,
        reduceLastChild ? "reduce-child" : null
      )}
      onClick={() => {
        if (sortable && !hideSort) return setSortOrder(header);
      }}
      colSpan={columns[header].colspan || 1}
    >
      <div>
        <div>{columns[header]?.headerComponent || header}</div>
        {sortable && !hideSort ? (
          <div>
            <i
              id={header}
              className={classNames(
                "icon-arrow-up",
                sortOrder === "ascending" && sortColumn === header
                  ? "active"
                  : null
              )}
            />
            <i
              id={header}
              className={classNames(
                "icon-arrow-down",
                sortOrder === "descending" && sortColumn === header
                  ? "active"
                  : null
              )}
            />
          </div>
        ) : null}
      </div>
    </th>
  );
}
