import React from "react";

export default function HowItWorks() {
  const data = {
    title: "How it works",
    steps: [
      "Choose the FirmUx option",
      "Sign the EULA",
      "Pay the yearly license",
      "Get the access to your own GitLab instant server and FirmUx source code",
    ],
  };

  return (
    <div className="section row">
      <div className="col-9 transparent">
        <div className="lg-title">{data.title}</div>
        <div className="row">
          {data.steps.map((x, i) => {
            return (
              <div key={i} className="col-md-3 col-sm-6">
                <div className="rounded-circle my-3">{i + 1}</div>
                <p className="description m-0 m-sm-md">{x}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
