import React, { useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import Collapse from "react-bootstrap/Collapse";

import Shop from "./assets/img/shop.svg";
import routes from "./Routes";
import Logo from "./assets/img/icons/8dev_logo.svg";

export default function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [collapsed, setCollapsed] = useState(false);

  useLayoutEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <nav className="navbar-top">
      <div className="block">
        <div className="logo-block">
          <a className="logo" href="https://www.8devices.com/">
            <img src={Logo} alt="8devices" />
          </a>
          <button
            aria-label="toggle navbar"
            aria-controls="collapse-menu"
            aria-expanded={!!collapsed}
            className={classNames(
              "navbar-toggle",
              !collapsed ? null : "collapsed"
            )}
            onClick={() => setCollapsed(!collapsed)}
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
        </div>
        <Collapse in={!!collapsed}>
          <div id="collapse-menu" className={classNames("top-main-menu")}>
            <ul className="main-items">
              {routes.map((x, i) => {
                if (!x.name) return;
                return (
                  <li
                    key={i}
                    className={classNames(
                      activeLink === x.path || (!activeLink && i === 0)
                        ? "active"
                        : null
                    )}
                  >
                    {x.href ? (
                      <a href={x.href}> {x.name}</a>
                    ) : (
                      <Link
                        to={x.path}
                        onClick={() => setCollapsed(!collapsed)}
                      >
                        {x.name}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
            <ul className="shop-menu-item">
              <li>
                <a href="http://shop.8devices.com">
                  Buy
                  <img src={Shop} />
                </a>
              </li>
            </ul>
          </div>
        </Collapse>
      </div>
    </nav>
  );
}
