import React, { useState, useEffect } from "react";

function CheckIfTablet(Component) {
  return function ComponentWithScreenWidthTracking(props) {
    const [width, setWidth] = useState(null);
    const [isTablet, setIsTablet] = useState(null);

    useEffect(() => {
      trackWindowWidth();
      window.addEventListener("resize", trackWindowWidth);

      return () => window.removeEventListener("resize", trackWindowWidth);
    });

    const trackWindowWidth = () => {
      const { innerWidth } = window || {};
      const isTabletWidth = innerWidth <= 992;
      setWidth(innerWidth);

      if (isTabletWidth != isTablet) {
        setIsTablet(isTabletWidth);
      }
    };

    const { ...passThroughProps } = props || {};
    return (
      <Component isTablet={isTablet} width={width} {...passThroughProps} />
    );
  };
}

export default CheckIfTablet;
