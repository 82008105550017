import React from "react";
import { ReactComponent as Check } from "../../../assets/img/icons/check.svg";

export default function SetInfo() {
  const data = {
    title: "The Right Set of Tools for Product Development",
    img: require("../../../assets/img/firmux-tools.png"),
    list: [
      "Simple architecture",
      "Responsive GUI",
      "Dual boot",
      "Up to 20 sec from powering the board to wireless connection",
      "Easy configuration (single .json file)",
      "Cloud agent",
    ],
  };

  return (
    <div className="section row">
      <div className="col-10 row">
        <div className="col-lg-6 col-md-12 p-0 text-center">
          <img src={data.img} className="img-fluid" alt="Firmux tools" />
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="lg-title text-start">{data.title}</div>
          <ul>
            {data.list.map((x, i) => (
              <div className="pb-4 d-flex flex-row align-items-center" key={i}>
                <Check fill="#4EAAE8" />
                <li className="text-start ms-3 w-75">{x}</li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
