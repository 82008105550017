import React from "react";
import classNames from "classnames";

export default function Spinner({ className }) {
  return (
    <div className={classNames("spinner", !className ? null : className)}>
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
  );
}
