import React, { useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

function getBgColor(path) {
  let className;
  switch (path) {
    case "/options":
      className = "white-bg";
      break;
    case "/notes":
      className = "white-bg";
      break;
    default:
      className = "dark-bg";
      break;
  }
  return className;
}

export default function Header({ routes }) {
  const location = useLocation();
  const [className, setClassName] = useState(getBgColor(location.pathname));
  const [activePath, setActivePath] = useState(location.pathname);

  useLayoutEffect(() => {
    setActivePath(location.pathname);
    setClassName(getBgColor(location.pathname));
  }, [location]);
  return (
    <nav className={classNames("navbar-container", "row", className || null)}>
      <ul className={classNames("navbar", "p-0", "col-10", className || null)}>
        {routes.map((x, i) => {
          const path = `/${x.path}`;
          if (!x.name) return;
          return (
            <li
              key={i}
              className={classNames(
                activePath === path || (!activePath && i === 0)
                  ? "active"
                  : null
              )}
            >
              <Link to={path} className="link">
                {x.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
