import React, { useState } from "react";
import { Tooltip } from "react-bootstrap";
import classNames from "classnames";

import CollapsableRow from "./CollapsableRow";
import CollapsableBlock from "./CollapsableBlock";
import Value from "./Value";

export default function TableRow({
  index,
  data,
  deleted,
  columns,
  headers,
  secondRowHeaders,
  isTablet,
  activeRow,
  handleToggle,
  emptySpansArray,
  searchValue,
  action,
  link,
  rowSpan,
  collapsable,
  highlightedRow,
  colWidth,
  noHeaders,
  className,
}) {
  const [isOpen, setIsOpen] = useState(false);
  let highlighted = false;
  let active2ndRowHeaders = secondRowHeaders;
  const disabled = deleted && deleted(data);

  if (highlightedRow) {
    const key = Object.keys(highlightedRow)[0];
    highlighted = highlightedRow[key] === data[key];
  }

  active2ndRowHeaders?.map((x) => {
    if (columns[x]?.hideOnTable) {
      if (columns[x].hideOnTable(data))
        active2ndRowHeaders = active2ndRowHeaders.filter((y) => y !== x);
    }
    if (!active2ndRowHeaders.length) data.hide = true;
  });

  let allowSecondRow = (collapsable && !data?.hide) || isTablet;

  activeRow = activeRow?.indexOf(index) > -1 ? index : null;

  const toggleTooltip = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <tr
        className={classNames(
          disabled ? "disabled" : null,
          allowSecondRow ? "arrow" : null,
          activeRow === index ? "active" : null,
          className || null
        )}
        style={{ backgroundColor: highlighted && "#c0defe" }}
      >
        {headers.map((header) => {
          const isTruncate = columns[header].className == "text-truncate";
          const hasValue =
            data[columns[header].value] && data[columns[header].value] != "-";
          return (
            <td
              id={header + index}
              key={header}
              onClick={() => {
                link && data[link] ? window.open("http://" + data[link]) : null;
                allowSecondRow && header.length ? handleToggle(index) : null;
                activeRow === index && data.callback ? data.callback() : null;
              }}
              style={
                colWidth
                  ? { width: colWidth + "%" }
                  : columns[header].width
                  ? {
                      width: columns[header].width,
                    }
                  : {}
              }
              className={classNames(
                columns[header].className ? columns[header].className : null,
                data?.className && !columns[header].visibleOnTablet
                  ? data.className
                  : null
              )}
              colSpan={columns[header].colspan || 1}
            >
              {allowSecondRow && (
                <i
                  className={classNames(
                    columns[header].className
                      ? columns[header].className
                      : null,
                    activeRow === index ? "icon-arrow-down" : "icon-arrow-right"
                  )}
                />
              )}

              {columns[header].component ? (
                <React.Fragment>
                  {columns[header].component(data, index, searchValue)}{" "}
                  {isTruncate && hasValue ? (
                    <Tooltip
                      placement="right"
                      isOpen={isOpen}
                      target={header + index}
                      toggle={toggleTooltip}
                    >
                      {columns[header].component(data, index, searchValue)}
                    </Tooltip>
                  ) : null}
                </React.Fragment>
              ) : (
                <Value
                  index={index}
                  header={header}
                  data={data}
                  columns={columns}
                  searchValue={searchValue}
                  isTruncate={isTruncate}
                  hasValue={hasValue}
                />
              )}
            </td>
          );
        })}

        {action ? (
          <td
            onClick={() => {
              action.func(data);
            }}
          >
            {action.component}
          </td>
        ) : null}

        {emptySpansArray &&
          !isTablet &&
          emptySpansArray.map((index) => <td key={index} />)}
      </tr>

      <tr className={activeRow === index && allowSecondRow ? null : "hidden"}>
        <td
          className={noHeaders ? "px-0 pt-0" : "px-0"}
          colSpan={
            emptySpansArray && !isTablet
              ? emptySpansArray.length + rowSpan
              : rowSpan
          }
        >
          {isTablet ? (
            <CollapsableBlock
              index={index}
              data={data}
              columns={columns}
              searchValue={searchValue}
              link={link}
            />
          ) : (
            <CollapsableRow
              index={index}
              data={data}
              columns={columns}
              headers={active2ndRowHeaders}
              searchValue={searchValue}
              emptySpans={
                emptySpansArray ? emptySpansArray.length + rowSpan : rowSpan
              }
              noHeaders={noHeaders}
            />
          )}
        </td>
      </tr>
    </>
  );
}
