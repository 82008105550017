import React from "react";
import Download from "../../../assets/img/icons/download.svg";

export default function Downloads() {
  const data = {
    title: "Downloads",
    icon: Download,
    list: [
      {
        title: "Datasheet",
        link: "https://8devices.com/media/firmux/firmux_datasheet.pdf",
      },
      {
        title: "User guide",
        link: "https://8devices.com/media/firmux/Firmux_user_guide_v1.11.0.pdf",
      },
    ],
  };

  return (
    <div className="section row">
      <div className="col-9 transparent">
        <div className="lg-title">{data.title}</div>
        <div className="row gy-3">
          {data.list.map((x, i) => {
            return (
              <div className="col-lg-4 col-sm-6" key={i}>
                <div className="row">
                  <div className="col-auto pe-0">
                    <img src={data.icon} alt="icon" />
                  </div>
                  <div className="col">
                    <a
                      href={x.link}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                      className="description fs-6"
                    >
                      {x.title}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
