import React from "react";
import "./assets/scss/style.scss";
import { BrowserRouter } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <BrowserRouter basename={"/firmux"}>
      <ScrollToTop>
        <div className="wrapper">
          <Header />
          <Content />
          <Footer />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
