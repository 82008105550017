import React from "react";
import Download from "../../../assets/img/icons/download.svg";
import classNames from "classnames";

export default function FirmwaresAndBootloaders({
  tableRow,
  archive,
  version,
}) {
  // version 1.11.0
  const data = {
    title: "Firmwares & Bootloaders",
    icon: Download,
    list: [
      {
        fw: {
          title: "Carambola 2 firmware",
          link: "https://images.firmux.io/Carambola2/Carambola2_zefir_basic-v1.9.0-6f14655-e81b4ac.bin",
        },
        bootloader: {
          title: "Carambola 2 bootloader",
          link: "https://images.firmux.io/Carambola2/caraboot-sdboot-carambola2-firmux.bin",
        },
      },
      {
        fw: {
          title: "Lima firmware",
          link: "https://images.firmux.io/Lima/Lima_zefir_basic-v1.9.0-6f14655-e81b4ac.bin",
        },
        bootloader: {
          title: "Lima bootloader",
          link: "https://images.firmux.io/Lima/caraboot-sdboot-lima-firmux.bin",
        },
      },
      {
        fw: {
          title: "Rambutan firmware",
          link: "https://images.firmux.io/Rambutan/Rambutan_zefir_basic-v1.9.0-6f14655-e81b4ac.ubi",
        },
        bootloader: {
          title: "Rambutan bootloader",
          link: "https://images.firmux.io/Rambutan/caraboot-sdboot-rambutan-firmux.bin",
        },
      },
      {
        fw: {
          title: "Habanero firmware",
          link: "https://images.firmux.io/Habanero/Habanero_zefir_basic-v1.12.1-546c214-55f88d0.bin",
        },
        bootloader: {
          title: "Habanero bootloader",
          link: "https://images.firmux.io/Habanero/caraboot-sdboot-habanero.elf",
        },
      },
      {
        fw: {
          title: "Jalapeno firmware",
          link: "https://images.firmux.io/Jalapeno/Jalapeno_zefir_basic-v1.12.1-546c214-55f88d0.bin",
        },
        bootloader: {
          title: "Jalapeno bootloader",
          link: "https://images.firmux.io/Jalapeno/caraboot-sdboot-jalapeno.elf",
        },
      },
      {
        fw: {
          title: "Mango firmware",
          link: "https://images.firmux.io/Mango/Mango_zefir_basic-v1.12.1-e21d7f2-55f88d0.bin",
        },
        bootloader: {
          title: "Mango bootloader",
          link: "https://images.firmux.io/Mango/caraboot-dboot-mango-firmux.mbn",
        },
      },
    ],
  };

  const data1110 = {
    title: "Firmwares & Bootloaders",
    icon: Download,
    list: [
      {
        fw: {
          title: "Habanero firmware",
          link: "https://images.firmux.io/Habanero/Habanero_zefir_basic-v1.11.0-ec4e46f-66e8cbc.bin",
        },
        bootloader: {
          title: "Habanero bootloader",
          link: "https://images.firmux.io/Habanero/caraboot-sdboot-habanero.elf",
        },
      },
      {
        fw: {
          title: "Jalapeno firmware",
          link: "https://images.firmux.io/Jalapeno/Jalapeno_zefir_basic-v1.11.0-ec4e46f-66e8cbc.bin ",
        },
        bootloader: {
          title: "Jalapeno bootloader",
          link: "https://images.firmux.io/Jalapeno/caraboot-sdboot-jalapeno.elf",
        },
      },
      {
        fw: {
          title: "Mango firmware",
          link: "https://images.firmux.io/Mango/Mango_zefir_basic-v1.11.0-0f0914c-66e8cbc.bin",
        },
        bootloader: {
          title: "Mango bootloader",
          link: "https://images.firmux.io/Mango/caraboot-dboot-mango-firmux.mbn",
        },
      },
    ],
  };

  const data1120 = {
    title: "Firmwares & Bootloaders",
    icon: Download,
    list: [
      {
        fw: {
          title: "Habanero firmware",
          link: "https://images.firmux.io/Habanero/Habanero_zefir_basic-v1.12.0-3f6b488-9418d77.bin",
        },
        bootloader: {
          title: "Habanero bootloader",
          link: "https://images.firmux.io/Habanero/caraboot-sdboot-habanero.elf",
        },
      },
      {
        fw: {
          title: "Jalapeno firmware",
          link: "https://images.firmux.io/Jalapeno/Jalapeno_zefir_basic-v1.12.0-3f6b488-9418d77.bin",
        },
        bootloader: {
          title: "Jalapeno bootloader",
          link: "https://images.firmux.io/Jalapeno/caraboot-sdboot-jalapeno.elf",
        },
      },
      {
        fw: {
          title: "Mango firmware",
          link: "https://images.firmux.io/Mango/Mango_zefir_basic-v1.12.0-056e089-9418d77.bin",
        },
        bootloader: {
          title: "Mango bootloader",
          link: "https://images.firmux.io/Mango/caraboot-dboot-mango-firmux.mbn",
        },
      },
    ],
  };

  const data1121 = {
    title: "Firmwares & Bootloaders",
    icon: Download,
    list: [
      {
        fw: {
          title: "Habanero firmware",
          link: "https://images.firmux.io/Habanero/Habanero_zefir_basic-v1.12.1-546c214-55f88d0.bin",
        },
        bootloader: {
          title: "Habanero bootloader",
          link: "https://images.firmux.io/Habanero/caraboot-sdboot-habanero.elf",
        },
      },
      {
        fw: {
          title: "Jalapeno firmware",
          link: "https://images.firmux.io/Jalapeno/Jalapeno_zefir_basic-v1.12.1-546c214-55f88d0.bin",
        },
        bootloader: {
          title: "Jalapeno bootloader",
          link: "https://images.firmux.io/Jalapeno/caraboot-sdboot-jalapeno.elf",
        },
      },
      {
        fw: {
          title: "Mango firmware",
          link: "https://images.firmux.io/Mango/Mango_zefir_basic-v1.12.1-e21d7f2-55f88d0.bin",
        },
        bootloader: {
          title: "Mango bootloader",
          link: "https://images.firmux.io/Mango/caraboot-dboot-mango-firmux.mbn",
        },
      },
    ],
  };

  const data1100 = {
    title: "Firmwares & Bootloaders",
    icon: Download,
    list: [
      {
        fw: {
          title: "Habanero firmware",
          link: "https://images.firmux.io/Habanero/Habanero_zefir_basic-v1.10.0-346c9f9-91e2fbd.bin",
        },
        bootloader: {
          title: "Habanero bootloader",
          link: "https://images.firmux.io/Habanero/caraboot-sdboot-habanero.elf",
        },
      },
      {
        fw: {
          title: "Jalapeno firmware",
          link: "https://images.firmux.io/Jalapeno/Jalapeno_zefir_basic-v1.10.0-346c9f9-91e2fbd.bin",
        },
        bootloader: {
          title: "Jalapeno bootloader",
          link: "https://images.firmux.io/Jalapeno/caraboot-sdboot-jalapeno.elf",
        },
      },
      {
        fw: {
          title: "Mango firmware",
          link: "https://images.firmux.io/Mango/Mango_zefir_basic-v1.10.0-d43499c-91e2fbd.bin",
        },
        bootloader: {
          title: "Mango bootloader",
          link: "https://images.firmux.io/Mango/caraboot-dboot-mango-firmux.mbn",
        },
      },
    ],
  };

  // versio 1.9.0
  const data190 = {
    title: "Firmwares & Bootloaders",
    icon: Download,
    list: [
      {
        fw: {
          title: "Carambola 2 firmware",
          link: "https://images.firmux.io/Carambola2/Carambola2_zefir_basic-v1.9.0-6f14655-e81b4ac.bin",
        },
        bootloader: {
          title: "Carambola 2 bootloader",
          link: "https://images.firmux.io/Carambola2/caraboot-sdboot-carambola2-firmux.bin",
        },
      },
      {
        fw: {
          title: "Lima firmware",
          link: "https://images.firmux.io/Lima/Lima_zefir_basic-v1.9.0-6f14655-e81b4ac.bin",
        },
        bootloader: {
          title: "Lima bootloader",
          link: "https://images.firmux.io/Lima/caraboot-sdboot-lima-firmux.bin",
        },
      },
      {
        fw: {
          title: "Rambutan firmware",
          link: "https://images.firmux.io/Rambutan/Rambutan_zefir_basic-v1.9.0-6f14655-e81b4ac.ubi",
        },
        bootloader: {
          title: "Rambutan bootloader",
          link: "https://images.firmux.io/Rambutan/caraboot-sdboot-rambutan-firmux.bin",
        },
      },
      {
        fw: {
          title: "Habanero firmware",
          link: "https://images.firmux.io/Habanero/Habanero_zefir_basic-v1.9.0-ea15ad6-e81b4ac.bin",
        },
        bootloader: {
          title: "Habanero bootloader",
          link: "https://images.firmux.io/Habanero/caraboot-sdboot-habanero.elf",
        },
      },
      {
        fw: {
          title: "Jalapeno firmware",
          link: "https://images.firmux.io/Jalapeno/Jalapeno_zefir_basic-v1.9.0-ea15ad6-e81b4ac.bin",
        },
        bootloader: {
          title: "Jalapeno bootloader",
          link: "https://images.firmux.io/Jalapeno/caraboot-sdboot-jalapeno.elf",
        },
      },
      {
        fw: {
          title: "Mango firmware",
          link: "https://images.firmux.io/Mango/Mango_zefir_basic-v1.9.0-dee06dc-e81b4ac.bin",
        },
        bootloader: {
          title: "Mango bootloader",
          link: "https://images.firmux.io/Mango/caraboot-dboot-mango-firmux.mbn",
        },
      },
    ],
  };

  // version 1.7.0
  const data170 = {
    title: "Firmwares & Bootloaders",
    icon: Download,
    list: [
      {
        fw: {
          title: "Carambola 2 firmware",
          link: "https://images.firmux.io/Carambola2/Carambola2_zefir_basic-v1.7.0-aec13ec-124b7c6.bin",
        },
        bootloader: {
          title: "Carambola 2 bootloader",
          link: "https://images.firmux.io/Carambola2/caraboot-sdboot-carambola2-firmux.bin",
        },
      },
      {
        fw: {
          title: "Lima firmware",
          link: "https://images.firmux.io/Lima/Lima_zefir_basic-v1.7.0-aec13ec-124b7c6.bin",
        },
        bootloader: {
          title: "Lima bootloader",
          link: "https://images.firmux.io/Lima/caraboot-sdboot-lima-firmux.bin",
        },
      },
      {
        fw: {
          title: "Rambutan firmware",
          link: "https://images.firmux.io/Rambutan/Rambutan_zefir_basic-v1.7.0-aec13ec-124b7c6.ubi",
        },
        bootloader: {
          title: "Rambutan bootloader",
          link: "https://images.firmux.io/Rambutan/caraboot-sdboot-rambutan-firmux.bin",
        },
      },
      {
        fw: {
          title: "Habanero firmware",
          link: "https://images.firmux.io/Habanero/Habanero_zefir_basic-v1.7.0-a063a5b-124b7c6.bin",
        },
        bootloader: {
          title: "Habanero bootloader",
          link: "https://images.firmux.io/Habanero/caraboot-sdboot-habanero.elf",
        },
      },
      {
        fw: {
          title: "Jalapeno firmware",
          link: "https://images.firmux.io/Jalapeno/Jalapeno_zefir_basic-v1.7.0-a063a5b-124b7c6.bin",
        },
        bootloader: {
          title: "Jalapeno bootloader",
          link: "https://images.firmux.io/Jalapeno/caraboot-sdboot-jalapeno.elf",
        },
      },
      {
        fw: {
          title: "Mango firmware",
          link: "https://images.firmux.io/Mango/Mango_zefir_basic-v1.7.0-ed67d45-124b7c6.bin",
        },
        bootloader: {
          title: "Mango bootloader",
          link: "https://images.firmux.io/Mango/caraboot-dboot-mango-firmux.mbn",
        },
      },
    ],
  };

  let visibleData = data;

  if (version == "v1.9.0") {
    visibleData = data190;
  }

  if (version == "v1.7.0") {
    visibleData = data170;
  }

  if (version == "v1.10.0") {
    visibleData = data1100;
  }

  if (version == "v1.11.0") {
    visibleData = data1110;
  }

  if (version == "v1.12.0") {
    visibleData = data1120;
  }

  if (version == "v1.12.1") {
    visibleData = data1121;
  }

  return (
    <div className={classNames("row", archive ? "" : "section")}>
      <div
        className={classNames(
          "transparent",
          tableRow ? "py-4 border-0 col-12" : "",
          archive ? "col-12" : "",
          !archive && !tableRow ? "col-9" : ""
        )}
      >
        {archive ? null : <div className="lg-title">{visibleData.title}</div>}
        <div className={classNames("row", tableRow ? "m-0" : null)}>
          {visibleData.list.map((x, i) => {
            return (
              <div className="col-xl-2 col-lg-3 border-0" key={i}>
                {Object.keys(x).map((y, index) => {
                  return (
                    <div
                      key={index}
                      className="row h-50 align-items-center justify-content-center py-2 text-start"
                    >
                      <div className="col-auto pe-0">
                        <img src={visibleData.icon} alt="icon" />
                      </div>
                      <div className="col">
                        <a
                          href={x[y].link}
                          download
                          rel="noopener noreferrer"
                          target="_blank"
                          className="description fs-6"
                        >
                          {x[y].title}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
