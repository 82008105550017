import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function NotFound() {
  return (
    <div className="not-found section row">
      <div className="col-9 text-center">
        <h1 className="mb-3">Error 404</h1>
        <div className="container py-3">
          <Row>
            <h2 className="mb-3">Page not found!</h2>
            <p className="mb-2">
              The page You are looking for does not exists or the link You are
              using is broken.
            </p>
            <p className="m-0">
              Please go back and try again or start at{" "}
              <Link to="/overview">home page</Link>.
            </p>
          </Row>
        </div>
      </div>
    </div>
  );
}
