import React from "react";

export default function RouteComp(props) {
  const { sections, name } = props;
  return (
    <div className="sections">
      {sections?.map((Sec, i) => (
        <Sec key={i} name={name} />
      ))}
    </div>
  );
}
