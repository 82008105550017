import React, { useState, useRef } from "react";
import classNames from "classnames";

import { ReactComponent as Check } from "../../../assets/img/icons/check.svg";
import { ReactComponent as Cross } from "../../../assets/img/icons/cross.svg";
import DrawTable from "../../../components/DrawTable/DrawTable";
import FirmwaresAndBootloaders from "../Overview/FirmwaresAndBootloaders";

function FirmuxOptions({ name }) {
  const title = <div className="primary-blue semi-bold">FirmUx</div>;
  const [expanded, setExpanded] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const data = [
    {
      title: "Functionality Overview",
      openWrt: [
        "The support of all our products is upstreamed in OpenWrt",
        "Single boot",
      ],
      basic: ["AP", "Router", "Bridge"],
      standard: [
        "Dual boot",
        "FirmUx AP",
        "Responsive UI",
        "Easy configuration (Single .json file)",
      ],
      premium: [
        "Dual Boot",
        "FirmUx AP / Mesh",
        "FirmUx cloud support",
        "Responsive UI",
        "Easy configuration (Single .json file)",
      ],
    },
    {
      title: "Driver/SDK",
      openWrt: "Open-source wireless drivers (ath9k/10k/11k)",
      basic: "FirmUx - maintained QSDK wireless driver from Qualcomm",
      standard: "FirmUx - maintained QSDK wireless driver from Qualcomm",
      premium:
        "Source code for Firmux router kit excluding vendor-restricted binaries (e.g. the QSDK driver)",
    },
    {
      title: "Software Upgrades",
      openWrt: "Check OpenWrt release schedule. Latest stable release 19.07.5",
      basic: "Latest software upgrade (currently 1.12.0)",
      standard: "Standard software upgrades",
      premium: "Standard software upgrades",
    },
    {
      title: "Engineering Support",
      openWrt:
        "Technical support charged on an hourly basis. Learn more on the support section (60 $/h)",
      basic:
        "Technical support charged on an hourly basis. Learn more on the support section (60 $/h)",
      standard:
        "Technical support charged on an hourly basis based on the service level agreement (60 $/h)",
      premium:
        "300 hours technical support hours per year. 2 business days response time",
    },
    {
      title: "UI Rebranding",
      openWrt: false,
      basic: false,
      standard: false,
      premium: true,
      className: "align-middle",
    },
    {
      title: "Annual Pricing",
      openWrt: "Free",
      basic: "Free",
      standard: [
        { count: "Devices 500+", cost: "$5000" },
        { count: "Devices 1000+", cost: "$8000" },
        { count: "Devices 2000+", cost: "$12000" },
        { count: "Devices 3000+", cost: "$15000" },
        { count: "Devices 4000+", cost: "$16000" },
        { count: "Devices 5000+", cost: "$18000" },
      ],
      premium: "$36000",
      disableListStyle: true,
    },
    {
      title: "Actions",
      callback: setExpanded,
      openWrt: {
        default: true,
        name: "Visit OpenWrt",
        link: "https://openwrt.org/toh/hwdata/8devices/start",
      },
      basic: {
        name: "Download",
        onClick: executeScroll,
      },
      standard: {
        name: "Get Yours",
        link: "mailto:info@8devices.com",
      },
      premium: {
        name: "Get Yours",
        link: "mailto:info@8devices.com",
      },
    },
  ];

  function returnColInfo(data, disableListStyle) {
    let res = data;
    if (typeof data === "boolean") {
      res = data ? <Check fill="#4EAAE8" /> : <Cross />;
    } else if (Array.isArray(data)) {
      res = (
        <div className={disableListStyle ? null : "ps-3"}>
          <ul>
            {data.map((x, i) => {
              return (
                <li key={i} className={disableListStyle ? null : "list"}>
                  {disableListStyle ? (
                    <div className="row">
                      {Object.keys(x).map((y, ind) => {
                        return (
                          <div
                            className={classNames(
                              "col",
                              ind !== 0 ? null : "label-fixed"
                            )}
                            key={ind}
                          >
                            {x[y]}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    x
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (typeof data === "object") {
      if (!data.link) {
        res = (
          <button
            className="btn rounded-pill py-2 px-3 flex-row d-flex justify-content-center border-primary-blue align-items-center"
            onClick={() => {
              setExpanded(!expanded);
              data.onClick && !expanded && data.onClick();
            }}
          >
            <i
              className={`me-2 primary-blue icon-arrow-${
                expanded ? "down" : "right"
              }`}
            />
            <p className="fs-6 primary-blue text-truncate m-0">{data.name}</p>
          </button>
        );
      } else {
        res = (
          <a
            href={data.link}
            className={classNames(
              "fs-6 text-truncate rounded-pill py-2 px-3",
              data.default ? "default-pill" : "border-primary-blue primary-blue"
            )}
          >
            {data.name}
          </a>
        );
      }
    }
    return res;
  }
  return (
    <div className="sections">
      <div className="section text-center row">
        <div className="col-9">
          <div className="lg-title">{name}</div>
          <DrawTable
            data={data}
            columns={{
              " ": {
                value: "title",
                visibleOnTablet: true,
                component: (d) => (
                  <span className="row-title opacity-50 semi-disp">
                    {d.title}
                  </span>
                ),
              },
              OpenWrt: {
                headerComponent: <div className="semi-bold">OpenWrt</div>,
                value: "openWrt",
                component: (d) => returnColInfo(d.openWrt, d.disableListStyle),
              },
              "Firmux Basic": {
                headerComponent: <>{title} Basic</>,
                value: "basic",
                component: (d) => returnColInfo(d.basic, d.disableListStyle),
              },
              "Firmux Standard": {
                headerComponent: <>{title} Standard</>,
                value: "standard",
                component: (d) => returnColInfo(d.standard, d.disableListStyle),
              },
              "Firmux Premium": {
                headerComponent: <>{title} Premium</>,
                value: "premium",
                component: (d) => returnColInfo(d.premium, d.disableListStyle),
              },
            }}
          />
          <div ref={myRef}>
            {expanded && <FirmwaresAndBootloaders homepage tableRow />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirmuxOptions;
