import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/icons/logo.svg";

export default function About() {
  const data = {
    title: "Empowering Developers with Simplicity",
    icon: Logo,
    img: require("../../../assets/img/firmux-cover.png"),
    text: "The embedded Linux framework FirmUx is primarily designed for building networking devices. FirmUx is focused towards product usability and easy feature development. Due to the simplistic and clever architecture, writing new code and uploading it to a device is extremely simple. FirmUx is designed by putting forward consistency, predictability and simplicity. The framework is constructed to empower developers by providing new tools and explicit integration procedures.",
  };

  return (
    <div className="section dark-content text-center row">
      <div className="col-9">
        <img className="mb-4" src={data.icon} alt="logo" />
        <div className="title">{data.title}</div>
        <img
          className="cover-img img-fluid"
          src={data.img}
          alt="firmux software cover"
        />
        <p className="mb-4">{data.text}</p>
        <div className="pt-3">
          <Link
            to="/options"
            className="primary-button light-bg text-light border-0"
          >
            Compare FirmUx Options
          </Link>
        </div>
      </div>
    </div>
  );
}
