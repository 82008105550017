import NotFound from "./NotFound";

import About from "./pages/home/Overview/About";
import SetInfo from "./pages/home/Overview/SetInfo";
import HowItWorks from "./pages/home/Overview/HowItWorks";
import WhyUseFirmux from "./pages/home/Overview/WhyUseFirmux";
import WirelessPerformance from "./pages/home/Overview/WirelessPerformance";
import KeySpecs from "./pages/home/Overview/KeySpecs";
import Downloads from "./pages/home/Overview/Downloads";
import FirmwaresAndBootloaders from "./pages/home/Overview/FirmwaresAndBootloaders";
import CompatibleDevices from "./pages/home/Overview/CompatibleDevices";
import EnginerSupport from "./pages/home/Overview/EnginerSupport";
import FirmuxOptions from "./pages/home/FirmuxOptions/FirmuxOptions";
import Releases from "./pages/home/Releases/Releases";

const routes = [
  {
    path: "/",
    tabs: [
      {
        name: "Overview",
        path: "overview",
        sections: [
          About,
          SetInfo,
          HowItWorks,
          WhyUseFirmux,
          WirelessPerformance,
          KeySpecs,
          Downloads,
          FirmwaresAndBootloaders,
          CompatibleDevices,
          EnginerSupport,
        ],
      },
      {
        name: "Firmux options",
        path: "options",
        sections: [FirmuxOptions],
      },
      {
        name: "Release notes",
        path: "notes",
        sections: [Releases],
      },
    ],
  },
  {
    name: "FirmUx",
    path: "firmux",
    href: "https://www.8devices.com/firmux",
  },
  {
    name: "Services",
    path: "services",
    href: "https://www.8devices.com/services",
  },
  {
    name: "Products",
    path: "products",
    href: "https://www.8devices.com/products",
  },
  {
    name: "Support",
    path: "support",
    href: "https://www.8devices.com/resources",
  },
  {
    name: "Distributors",
    path: "distributors",
    href: "https://www.8devices.com/distributors",
  },
  {
    name: "News",
    path: "news",
    href: "https://www.8devices.com/news",
  },
  {
    name: "Contact Us",
    path: "contactUs",
    href: "https://www.8devices.com/contact",
  },
  {
    name: "EU projects",
    path: "EUprojects",
    href: "https://www.8devices.com/euprojects",
  },
  {
    path: "*",
    sections: [NotFound],
  },
];

export default routes;
