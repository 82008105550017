import React, { useState } from "react";
import classNames from "classnames";
import Wifi4 from "../../../assets/img/products/product_icons/WiFi-4.svg";
import Wifi5 from "../../../assets/img/products/product_icons/WiFi-5.svg";
import Wifi6 from "../../../assets/img/products/product_icons/WiFi-6.svg";
import Wifi6E from "../../../assets/img/products/product_icons/WiFi-6E.svg";

export default function CompatibleDevices() {
  const [activeWifi, setActiveWifi] = useState("WiFi 5");
  const data = {
    title: "Compatible Devices",
    list: ["WiFi 4", "WiFi 5", "WiFi 6"],
    cards: {
      "WiFi 4": [
        {
          img: require("../../../assets/img/products/Carambola.png"),
          title: "Carambola 2",
          desc: "Wi-Fi enabled Linux module, featuring the lowest power consumption in the industry.",
          icon: Wifi4,
          link: "https://www.8devices.com/products/carambola-2",
        },
        {
          img: require("../../../assets/img/products/Lima.png"),
          title: "Lima",
          desc: "Has 802.11n 2x2 radio supporting up to 300 Mbps data-rate and comes in two versions.",
          icon: Wifi4,
          link: "https://www.8devices.com/products/lima",
        },
        {
          img: require("../../../assets/img/products/Rambutan.png"),
          title: "Rambutan",
          desc: "Dual - band module with a 720 Mhz CPU and 128 MB of Ram and Flash.",
          icon: Wifi4,
          link: "https://www.8devices.com/products/rambutan",
        },
      ],
      "WiFi 5": [
        {
          img: require("../../../assets/img/products/Habanero.png"),
          title: "Habanero",
          desc: "Fast and interface rich 802.11ac Wave2 SOM.",
          icon: Wifi5,
          link: "https://www.8devices.com/products/habanero",
        },
        {
          img: require("../../../assets/img/products/Jalapeno.png"),
          title: "Jalapeno",
          desc: "Powerful Dual - Band SOM, 802.11ac Wave2.",
          icon: Wifi5,
          link: "https://www.8devices.com/products/jalapeno",
        },
      ],
      "WiFi 6": [
        {
          img: require("../../../assets/img/products/Mango.png"),
          title: "Mango",
          desc: "Powerful dual - band SOM based on IPQ6010 SoC, 802.11ax.",
          icon: Wifi6,
          link: "https://www.8devices.com/products/mango",
        },
        {
          img: require("../../../assets/img/products/Pineapple.png"),
          title: "Pineapple",
          desc: "802.11ax 6 and 6E radio module designed for high speed enterprise connectivity.",
          icon: Wifi6E,
          link: "https://www.8devices.com/products/pineapple",
        },
      ],
    },
  };

  return (
    <div className="section text-center row">
      <div className="col-9">
        <div className="lg-title">{data.title}</div>
        <div className="row justify-content-center mb-4">
          {data.list.map((x, i) => {
            return (
              <button
                className={classNames(
                  "col-lg-1",
                  "w-auto",
                  "mb-3",
                  "btn",
                  activeWifi === x ? "active" : null
                )}
                key={i}
                onClick={() => setActiveWifi(x)}
              >
                <div className="rounded-pill px-4 py-3 fs-6"> {x}</div>
              </button>
            );
          })}
        </div>
        <div className="row justify-content-sm-center justify-content-lg-start g-4">
          {data.cards[activeWifi].map((x, i) => {
            return (
              <div className="col-xl-4 col-lg-5 col-md-7 col-sm-8" key={i}>
                <div className="border rounded-sm card h-100">
                  <div className="device-col p-3 rounded-sm rounded-end-0 mb-3">
                    <div className="d-flex justify-content-end">
                      <img src={x.icon} className="img-fluid" alt="product" />
                    </div>
                    <img src={x.img} className="img-fluid" alt="product" />
                  </div>
                  <div className="p-3">
                    <div className="title mb-3">{x.title}</div>
                    <div className="description mb-3">{x.desc}</div>
                    <>
                      <a
                        href={x.link}
                        className="primary-blue align-items-center fs-6 mb-2"
                      >
                        Learn More
                        <i className="icon-arrow-right align-middle ms-2 primary-blue" />
                      </a>
                    </>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
