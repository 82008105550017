import React, { useState } from "react";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const socialNetworks = [
    {
      link: "https://www.facebook.com/8devices",
      icon: "icon-fb",
    },
    {
      link: "https://twitter.com/8devices",
      icon: "icon-twitter",
    },
    {
      link: "https://www.youtube.com/user/8devices",
      icon: "icon-youtube",
    },
  ];

  async function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    const url = new URL("https://app.mailerlite.com/webforms/submit/h1l2z6"),
      params = {
        "fields[email]": email,
        "ml-submit": 1,
        ajax: 1,
      };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    return await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        if (!data.success) {
          setStatus({ error: "Email address is not valid!" });
        } else
          setStatus({
            success: "You have successfully subscribed to our newsletter!",
          });
      })
      .finally(() => {
        setEmail("");
        setLoading(false);
      });
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5>Contact Us</h5>
            <div className="row">
              <div className="col-md-4 col col-sm-12 mb-3">
                <div className="d-flex flex-column">
                  <a className="text-truncate" href="mailto:info@8devices.com">
                    info@8devices.com
                  </a>
                  <a href="tel:+37052001014">+37052001014</a>
                </div>
              </div>
              <div className="col-md-4 col mb-3">
                <div className="d-flex flex-column">
                  <a href="https://shop.8devices.com/privacy-policy">
                    Privacy Policy
                  </a>
                  <a href="https://www.8devices.com/cookies">Cookies</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <h5>SIGN UP FOR OUR NEWSLETTER</h5>
            <div className="position-relative">
              <form className="mb-4" onSubmit={handleSubmit}>
                <input
                  className="w-100"
                  placeholder="Email address*"
                  value={email}
                  onChange={(e) => {
                    if (status) setStatus(null);
                    setEmail(e.target.value);
                  }}
                />
                <button
                  aria-label="submit"
                  className="position-absolute"
                  type="submit"
                  disabled={loading}
                >
                  {!loading ? (
                    <i className="icon-arrow-right" />
                  ) : (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                  )}
                </button>
                {!status?.error ? null : (
                  <div className="has-msg text-danger">{status.error}</div>
                )}
                {!status?.success ? null : (
                  <div className="has-msg text-success">{status.success}</div>
                )}
              </form>
            </div>
          </div>
          <div className="col-md-2">
            <h5>Find Us On</h5>
            <ul className="social-networks d-inline-block">
              {socialNetworks.map((x, i) => (
                <li key={i}>
                  <a className="rounded-circle" href={x.link}>
                    <i className={x.icon} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="text-center mt-4">
          © 2012-2022 UAB &quot;8devices&quot;. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}
