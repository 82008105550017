import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import routes from "./Routes";
import RouteComp from "./RouteComp";
import TabHeader from "./TabHeader";
import { cacheImages } from "./components/CacheImages";
import Spinner from "./components/tools/Spinner";

export default function Content() {
  const [loadingAssets, setLoadingAssets] = useState(true);
  const route = (x, i) => {
    return <Route key={i} path={x.path} index element={<RouteComp {...x} />} />;
  };

  useEffect(() => {
    (async () => {
      await cacheImages();
      setLoadingAssets(false);
    })();
  }, []);

  return (
    <div className="content">
      {loadingAssets ? (
        <div className="text-center">
          <div className="col-9 mx-auto">
            <Spinner className="my-5" />
          </div>
        </div>
      ) : (
        <Routes>
          {routes.map((x, i) => {
            if (x.tabs) {
              return (
                <Route
                  path={x.path}
                  element={
                    <div>
                      <TabHeader routes={x.tabs} />
                      <main>
                        <Outlet />
                      </main>
                    </div>
                  }
                  key={i}
                >
                  <Route index element={<Navigate to="/overview" />} />;
                  {x.tabs.map((tab, index) => route(tab, index))}
                </Route>
              );
            } else return route(x, i);
          })}
          <Route path="/" index element={<Navigate to="/overview" />} />
        </Routes>
      )}
    </div>
  );
}
