import React from "react";
import Accordion from "react-bootstrap/Accordion";
import FirmwaresAndBootloaders from "../Overview/FirmwaresAndBootloaders";

function Releases({ name }) {
  return (
    <div className="sections">
      <div className="section text-left row">
        <div className="col-9">
          <div className="lg-title">{name}</div>
          <div className="text-center mb-4">
            S - Applicable only to FirmUx Standard/Premium. B - Applicable to
            all FirmUx tiers, including Basic
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Firmux v1.12.1</Accordion.Header>
              <Accordion.Body>
                <h5 className="my-3">New features:</h5>
                <h5 className="my-3">Improvements:</h5>
                <ul>
                  <li>
                    Improve UI performance when many inputs with validation are
                    visible at the same time [B]
                  </li>
                </ul>
                <h5 className="my-3">Bug Fixes:</h5>
                <ul>
                  <li>Fix PVID setup on bridge [S]</li>
                  <li>Fix netmask configuration and display issues [B]</li>
                  <li>Fix icon alignment in web UI header [B]</li>
                  <li>Fix LLDP discovery not sending interface name [B]</li>
                  <li>
                    Fix sidebar not showing all options when collapsed [B]
                  </li>
                  <li>Fix default route metric validation issues [S]</li>
                  <li>
                    Fix secondary IP and static DHCP lease change tracking
                    issues [S]
                  </li>
                  <li>
                    Fix network zone page sometimes showing changes when there
                    aren&apos;t any [B]
                  </li>
                  <li>
                    Fix missing validation in Station mode WPA-Enterprise
                    settings [B]
                  </li>
                  <li>
                    Fix configuration confirm issue in config test mode [S]
                  </li>
                  <li>
                    Fix an issue where client signals would always be shown as
                    -95 dBm [B]
                  </li>
                </ul>
                <FirmwaresAndBootloaders archive version="v1.12.1" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Firmux v1.12.0</Accordion.Header>
              <Accordion.Body>
                <h5 className="my-3">New features:</h5>
                <ul>
                  <li>
                    Separate throughput graphs for each wireless client [B]
                  </li>
                  <li>
                    Multiple OpenVPN instances can be enabled at the same time
                    [S]
                  </li>
                </ul>
                <h5 className="my-3">Improvements:</h5>
                <ul>
                  <li>
                    Show channel selection list even when radio is disabled [B]
                  </li>
                  <li>Multiple wireless clients can be kicked at once [B]</li>
                  <li>
                    Ad block lists can now be previewed in the dashboard [S]
                  </li>
                  <li>
                    Improve wizard change summary to be more human readable [B]
                  </li>
                  <li>Improve web management performance and security [B]</li>
                  <li>
                    Subnet conflict validation takes into account IPv6 addresses
                    [S]
                  </li>
                  <li>
                    Added color coded icon to indicate wireless signal level [B]
                  </li>
                  <li>
                    Make Web shell use the same port as the main UI and be
                    enabled by default [S]
                  </li>
                  <li>Add VLAN info to dashboard network tab [S]</li>
                </ul>
                <h5 className="my-3">Bug Fixes:</h5>
                <ul>
                  <li>
                    Open the right config tab when clicking on a network member
                    in the dashbaord [B]
                  </li>
                  <li>Fix WiFi 6E (6 GHz band) radio display in UI [B]</li>
                  <li>
                    Fix missing error message when firmware upload fails in
                    wizard [B]
                  </li>
                  <li>
                    Fix wireless mode and radio selects in wizard Wireless
                    bridge scenario [B]
                  </li>
                  <li>
                    Fix channel display issue when a single channel is selected
                    [B]
                  </li>
                  <li>Fix wireless scan not working in wizard [B]</li>
                  <li>
                    Fix validation warning sometimes overlaping with input
                    fields [B]
                  </li>
                  <li>
                    Fix config changes being remembered in UI even after device
                    reboot [B]
                  </li>
                  <li>Fix network stats info card sorting and alignment [B]</li>
                  <li>
                    Fix UI error when changing radio in the middle of site
                    survey scan [B]
                  </li>
                  <li>
                    Fix device becoming unreachable after system-reload is
                    executed via Web shell [S]
                  </li>
                  <li>
                    Fix host name validation not allowing numbers in some cases
                    [B]
                  </li>
                  <li>
                    Fix disabling SSH password login not working on some devices
                    [S]
                  </li>
                  <li>
                    Fix an issue where it was possible to click Save button
                    twice [B]
                  </li>
                </ul>
                <FirmwaresAndBootloaders archive version="v1.12.0" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Firmux v1.11.0</Accordion.Header>
              <Accordion.Body>
                <h5 className="my-3">New features:</h5>
                <ul>
                  <li>VLAN configuration page [S]</li>

                  <li>
                    VLAN trunk and pass-through control for individual ports [S]
                  </li>

                  <li>Device shell on the web page tool [S]</li>

                  <li>VPN configuration page [S]</li>

                  <li>ZeroTier VPN [S]</li>

                  <li>
                    DNS filtering based ad-blocker with custom blocklist support
                    [S]
                  </li>

                  <li>
                    Multiple secondary IP addresses can be set for each network
                    zone [S]
                  </li>

                  <li>
                    Show additional info about DHCP clients based on their MAC
                    address [B]
                  </li>
                </ul>
                <h5 className="my-3">Improvements:</h5>
                <ul>
                  <li>Add descriptions to many controls in the UI [B]</li>

                  <li> Add more instant configuration changes [S]</li>

                  <li>
                    Dashboard interfaces tab now clearly shows which interfaces
                    are disabled [B]
                  </li>

                  <li>
                    Improve and add more info to OpenVPN status in dashboard [S]
                  </li>

                  <li>Improve device info in dashboard [B]</li>

                  <li>
                    {" "}
                    DNS set by connecting to OpenVPN are now being used [S]
                  </li>

                  <li>Improve configuration changes list in the UI [B]</li>

                  <li>
                    WireGuard NAT and WAN/gateway can be controlled separately
                    [S]
                  </li>
                </ul>
                <h5 className="my-3">Bug Fixes:</h5>
                <ul>
                  <li>
                    Fix initial setup wizard summary not showing all changes [B]
                  </li>

                  <li>
                    Fix initial setup wizard not being shown after refreshing
                    the page [B]
                  </li>

                  <li>
                    Fix dashboard clients tab not updating periodically [B]
                  </li>

                  <li>Fix DNS info sometimes missing in dashboard [B]</li>

                  <li>
                    Fix unreachable device warning being shown when fetching
                    thoubleshooting file [S]
                  </li>

                  <li>
                    Fix hostname in DHCP client requests not being updated after
                    changing it in the UI [B]
                  </li>

                  <li>
                    Do not show IPv6 choice in ping and traceroute tools when
                    IPv6 is not supported [B]
                  </li>

                  <li>
                    Fix UI becoming unreachable while wireless scan is running
                    [B]
                  </li>

                  <li>
                    Fix UI becoming slow and unresponsive when many networks
                    and/or zones are added [B]
                  </li>
                </ul>
                <FirmwaresAndBootloaders archive version="v1.11.0" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Firmux v1.10.0</Accordion.Header>
              <Accordion.Body>
                <h5 className="my-3">New features:</h5>
                <ul>
                  <li>OpenVPN tunnel [S]</li>
                  <li>LAN zone isolation [B]</li>
                  <li>
                    Mobile broadband router mode in initial setup wizard [S]
                  </li>
                  <li>Multiple simultaneous WireGuard VPN instances [S]</li>
                  <li>
                    WireGuard can be used as WAN zone with NAT enabled [S]
                  </li>
                </ul>
                <h5 className="my-3">Improvements:</h5>
                <ul>
                  <li>
                    Add separate Mobile broadband configuration page with
                    additional options [S]
                  </li>
                  <li>
                    Add separate modal window with additional info for wireless
                    channel select [B]
                  </li>
                  <li>
                    Improve and add more info to mobile connection status card
                    [S]
                  </li>
                  <li>
                    Limit max allowed TX power according to selected channel and
                    antenna gain [B]
                  </li>
                  <li>
                    Improve wireless station mode when connecting to non-WDS AP
                    [B]
                  </li>
                  <li>Show device actions menu in initial setup wizard [B]</li>
                </ul>
                <h5 className="my-3">Bug Fixes:</h5>
                <ul>
                  <li>
                    Fix being able to enter multiple WireGuard peers with the
                    same public key [S]
                  </li>
                  <li>
                    Fix config reset after upgrade feature on single-boot
                    devices [B]
                  </li>
                  <li>Fix discovery tool table header styling [S]</li>
                  <li>Fix instant changes not working in Firmux basic [B]</li>
                  <li>
                    Fix network zone page generating invalid config in rare
                    cases [B]
                  </li>
                  <li>
                    Fix incorrect last handshake time in WireGuard statistics
                    [S]
                  </li>
                </ul>
                <h5 className="my-3">Notes / Known issues:</h5>
                <ul>
                  <li>
                    After firmware update DHCP IP acquired by the device might
                    change if WAN zone has multiple interfaces assigned and at
                    least one of them is a wireless interface [B]
                  </li>
                </ul>
                <FirmwaresAndBootloaders archive version="v1.10.0" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Firmux v1.9.0</Accordion.Header>
              <Accordion.Body>
                <h5 className="my-3">New features:</h5>
                <ul>
                  <li>
                    Initial setup wizard with common configuration scenarios [B]
                  </li>
                  <li>Search function in UI [B]</li>
                  <li>Local DNS hosts configuration [S]</li>
                  <li>DNS hosts blackhole configuration [S]</li>
                  <li>Show connected WireGuard endpoints in UI [S]</li>
                  <li>
                    Route network selection for each LAN in multi-WAN scenario
                    [S]
                  </li>
                </ul>
                <h5 className="my-3">Improvements:</h5>
                <ul>
                  <li>SSH keys now persist after firmware upgrade [B]</li>
                  <li>
                    Same static DHCP IP lease can be assigned to multiple MAC
                    addresses [S]
                  </li>
                  <li>
                    Silence repeating identical DHCP lease renew messages in
                    activity log [S]
                  </li>
                  <li>
                    Attempt to continue system start even if one of the setup
                    tasks has failed [B]
                  </li>
                  <li>Improve dashboard cards style and text formatting [B]</li>
                </ul>
                <h5 className="my-3">Bug Fixes:</h5>
                <ul>
                  <li>
                    Fix rare RRD database corruption that would break throughput
                    graphs [B]
                  </li>
                  <li>
                    Fix throughput graph error after creating new WAN zone [S]
                  </li>
                  <li>Fix ‘See all events’ button in UI [S]</li>
                  <li>Fix ‘Ignore DFS CAC’ functionality on 11ac radios [B]</li>
                  <li>
                    Fix Discard button sometimes not discarding changes [B]
                  </li>
                  <li>Fix UI issues when change test mode is aborted [S]</li>
                  <li>
                    Fix missing automatic MAC formatting on some UI input fields
                    [B]
                  </li>
                  <li>Fix IP validation in traceroute tool [B]</li>
                  <li>
                    Fix missing radio and SSID info in Connections table [B]
                  </li>
                  <li>Fix incorrect configuration change paths in UI [B]</li>
                  <li>
                    Fix graph filter not showing all options when more zones are
                    created [S]
                  </li>
                  <li>Fix DNS info in dashboard with multiple WAN zones [S]</li>
                </ul>
                <FirmwaresAndBootloaders archive version="v1.9.0" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Firmux v1.7.0</Accordion.Header>
              <Accordion.Body>
                <h5 className="my-3">New features:</h5>
                <ul>
                  <li>
                    AP and Station wireless modes can be enabled simultaneously
                    [B]
                  </li>
                  <li>WPA3 personal and enterprise encryption modes [S]</li>
                  <li>SSH login using authorized keys [S]</li>
                  <li>Configurable dashboard UI [B]</li>
                  <li>
                    Custom MAC address configuration for each network zone [B]
                  </li>
                  <li>DNS servers override when in DHCP client mode [B]</li>
                  <li>Ping Watchdog service [S]</li>
                  <li>Additional filtering options for graphs [B]</li>
                  <li>Installer user role [S]</li>
                </ul>
                <h5 className="my-3">Improvements:</h5>
                <ul>
                  <li>Improved dashboard UI layout [B]</li>
                  <li>Improved validation error styling [B]</li>
                  <li>Periodically refresh UI statistics [B]</li>
                  <li>Add more events to indicate DHCP client status [S]</li>
                  <li>
                    Add option to reset device to factory defaults after
                    firmware update [B]
                  </li>
                  <li>Add wireless peer count graphs for each radio [B]</li>
                  <li>
                    Improve WireGuard configuration UI and add IPv6 support [S]
                  </li>
                  <li>
                    Add possibility to select exact IEEE 802.11 mode for each
                    radio [S]
                  </li>
                  <li>Channel list changes are now instant [B]</li>
                  <li>VLAN info is now shown in network status tab [S]</li>
                  <li>Show firmware revision and build date in UI [B]</li>
                  <li>Improved configuration menu navigation [B]</li>
                  <li>Graphs can now be viewed in maximized mode [B]</li>
                </ul>
                <h5 className="my-3">Bug Fixes:</h5>
                <ul>
                  <li>Fix missing early events after device boot [S]</li>
                  <li>
                    Fix an issue where only the first WireGuard peer would be
                    able to connect [S]
                  </li>
                  <li>
                    Fix and issue where sent device discovery info sometimes was
                    not being updated [B]
                  </li>
                  <li>Fix Data VLAN ID validation in UI [S]</li>
                  <li>Fix link uptime sometimes showing incorrect value [B]</li>
                </ul>
                <FirmwaresAndBootloaders archive version="v1.7.0" />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Releases;
