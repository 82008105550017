import React from "react";
import Firmux from "../../../assets/img/icons/wirelessPerformance/firmux.svg";
import OpenWrt from "../../../assets/img/icons/wirelessPerformance/openWrt.svg";

export default function WirelessPerformance() {
  const data = {
    title: "Simply Better Wireless Performance",
    desc: "Made Possible by the Proprietary QSDK Drivers & FirmUx",
    text: "Check out the average throughput comparison of Mango boards running both on OpenWrt platform and FirmUx framework. The 34% performance difference speaks for itself.",
    columns: [
      {
        img: Firmux,
        title: "FirmUx Performance",
        desc: "897 Mbps",
      },
      {
        img: OpenWrt,
        title: "OpenWrt Performance",
        desc: "677 Mbps",
      },
    ],
  };

  function returnSpinnerWithAnimation() {
    return (
      <div className="position-relative w-100 h-100">
        <div className="animating" />
        <div className="animating s-1" />
        <div className="animating s-2" />
        <div className="animating s-3" />
      </div>
    );
  }

  return (
    <>
      <div className="section dark-content text-center row">
        <div className="col-9">
          <>
            <div className="lg-title text-white">{data.title}</div>
            <div className="title">{data.desc}</div>
            <p className="mb-6">{data.text}</p>
          </>
          <div className="row justify-content-md-center">
            {data.columns.map((x, i) => {
              return (
                <div className="performance-stat col-lg-6 col-md-8" key={i}>
                  <div className="rounded py-5 px-4">
                    <div className="float-end total">Total Mbps</div>
                    <img src={x.img} className="w-100 mb-2" alt="wireless" />
                    <div className="description subtitle">{x.title}</div>
                    <div className="lg-title m-0">{x.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="section position-relative row p-0">
        <div className="dark-bg position-absolute h-50" />
        <div className="col-9">
          <div className="w-50 mx-auto position-relative firmux-demo">
            <div
              className="position-absolute middle-spinner"
              style={{
                right: "4%",
                top: "-4%",
              }}
            >
              {returnSpinnerWithAnimation()}
            </div>
            <img
              src={require("../../../assets/img/firmux-demo.png")}
              alt="firmux demo"
              className="img-fluid"
            />
            <div
              className="position-absolute my-auto middle-spinner"
              style={{
                top: 0,
                bottom: 0,
                left: "4%",
              }}
            >
              {returnSpinnerWithAnimation()}
            </div>
          </div>
        </div>
      </div>
      <div className="section text-center row">
        <div className="col-9 transparent pt-0">
          <div>
            <div className="lg-title mb-4">Try FirmUx Now</div>
            <p className="description mb-md">
              To help you better understand the features offered by FirmUx, we
              would like to invite to check the online demo of FirmUx
            </p>
            <a
              href="http://demo.firmux.io/"
              className="primary-button primary-blue"
            >
              Go to Demo
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
