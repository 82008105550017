import React from "react";

export default function EnginerSupport() {
  const data = {
    title: "Engineering Support",
    desc: "If you are experiencing any technical difficulties with FirmUx framework, we advise to check the documentation section. Additionally, 8devices has a strong in-house engineering team that can help you with all FirmUx related questions and issues. On standard version technical support will be charged on an hourly basis based on the service level agreement. The premium Firmux version includes 300 hours of free engineering support services.",
    img: require("../../../assets/img/support.jpg"),
  };
  return (
    <div className="section row">
      <div className="col-10">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 col-md-12">
            <img
              src={data.img}
              className="rounded-sm img-fluid"
              alt="support"
            />
          </div>
          <div className="col-lg-5">
            <div className="lg-title text-start">{data.title}</div>
            <p className="mb-4">{data.desc}</p>
            <a
              href="https://www.8devices.com/resources"
              className="primary-button primary-blue"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
