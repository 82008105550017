import React from "react";

import Configuration from "../../../assets/img/icons/whyUseFirmUx/configuration.svg";
import Management from "../../../assets/img/icons/whyUseFirmUx/management.svg";
import Rebranding from "../../../assets/img/icons/whyUseFirmUx/rebranding.svg";
import Statistics from "../../../assets/img/icons/whyUseFirmUx/statistics.svg";
import Updates from "../../../assets/img/icons/whyUseFirmUx/updates.svg";
import Vpn from "../../../assets/img/icons/whyUseFirmUx/vpn.svg";

export default function WhyUseFirmux() {
  const data = {
    title: "Why use FirmUx?",
    desc: "Tailored Solution for Commercial Grade Product",
    columns: [
      {
        img: Configuration,
        title: "Configuration",
        text: "End-users can easily adjust the parameters of individual devices via FirmUx GUI. During boot or system reload, configuration data is used to setup network interfaces and network topology, as well as setup system files and start services with formatted service specific configuration. Configuration files are stored in JSON format. Additionally FirmUx allows you to take advantage of the custom Qualcomm wireless driver features and makes it possible to add those directly to the UI if desired.",
      },
      {
        img: Management,
        title: "Management",
        text: "FirmUx provides multiple management options including web GUI through HTTP(S) service and console management via SSH, Telnet or serial port. Management VLAN can be configured using either IPv4 or IPv6 addresses. Additionally FirmUx enables the use of SNMPv2 and SNMPv3 network management protocols. Cloud management is also available for FirmUx Premium devices.",
      },
      {
        img: Vpn,
        title: "VPN Applications",
        text: "FirmUx will also serve as a helpful tool in building VPN applications. It has a built-in open-source WireGuard® solution that was initially released for Linux kernel but is now also widely deployable on macOS, Windows, BSD and Android operating systems.",
      },
      {
        img: Rebranding,
        title: "Rebranding",
        text: "Premium version of FirmUx allows clients to customize the look and feel of the GUI. This includes applying the company’s logo & corporate colors so that your brand could be represented properly.",
      },
      {
        img: Updates,
        title: "Automatic Updates",
        text: "With the automatic update functionality the FirmUx powered board is able to periodically check for the latest available firmware versions. When a new firmware is available on the server, a notification will appear on the FirmUx dashboard.",
      },
      {
        img: Statistics,
        title: "Statistics",
        text: "JSON format files provide easy access to different types of statistics available on the FirmUx device — system, internal Linux interfaces, Ethernet interfaces, wireless interfaces and internet throughput.",
      },
    ],
  };

  return (
    <div className="section row">
      <div className="col-9">
        <div className="lg-title mb-4">{data.title}</div>
        <div className="title description mb-5">{data.desc}</div>
        <div className="row gy-5">
          {data.columns.map((x, i) => {
            return (
              <div key={i} className="col-lg-4 col-md-6">
                <img src={x.img} className="mb-4" alt="solution" />
                <div className="title text-start mb-3">{x.title}</div>
                <p className="description">{x.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
