import Firmux from "../assets/img/icons/wirelessPerformance/firmux.svg";
import OpenWrt from "../assets/img/icons/wirelessPerformance/openWrt.svg";
import Configuration from "../assets/img/icons/whyUseFirmUx/configuration.svg";
import Management from "../assets/img/icons/whyUseFirmUx/management.svg";
import Rebranding from "../assets/img/icons/whyUseFirmUx/rebranding.svg";
import Statistics from "../assets/img/icons/whyUseFirmUx/statistics.svg";
import Updates from "../assets/img/icons/whyUseFirmUx/updates.svg";
import Vpn from "../assets/img/icons/whyUseFirmUx/vpn.svg";
import Check from "../assets/img/icons/check.svg";
import Cloud from "../assets/img/icons/keySpecs/cloud.svg";
import KeyConfiguration from "../assets/img/icons/keySpecs/configuration.svg";
import Dual from "../assets/img/icons/keySpecs/dual.svg";
import RealTime from "../assets/img/icons/keySpecs/real-time.svg";
import Responsive from "../assets/img/icons/keySpecs/responsive.svg";
import Speedtest from "../assets/img/icons/keySpecs/speedtest.svg";
import Wireguard from "../assets/img/icons/keySpecs/wireguard.svg";
import Wpa3 from "../assets/img/icons/keySpecs/wpa3.svg";
import Download from "../assets/img/icons/download.svg";
import Wifi4 from "../assets/img/products/product_icons/WiFi-4.svg";
import Wifi5 from "../assets/img/products/product_icons/WiFi-5.svg";
import Wifi6 from "../assets/img/products/product_icons/WiFi-6.svg";
import Wifi6E from "../assets/img/products/product_icons/WiFi-6E.svg";
import Logo from "../assets/img/icons/logo.svg";
import Carambola from "../assets/img/products/Carambola.png";
import Lima from "../assets/img/products/Lima.png";
import Rambutan from "../assets/img/products/Rambutan.png";
import Habanero from "../assets/img/products/Habanero.png";
import Jalapeno from "../assets/img/products/Jalapeno.png";
import Mango from "../assets/img/products/Mango.png";
import Pineapple from "../assets/img/products/Pineapple.png";
import FirmuxCover from "../assets/img/firmux-cover.png";
import Support from "../assets/img/support.jpg";
import FirmuxTools from "../assets/img/firmux-tools.png";
import FirmuxDemo from "../assets/img/firmux-demo.png";

const images = [
  FirmuxCover,
  Support,
  FirmuxTools,
  FirmuxDemo,
  Carambola,
  Lima,
  Rambutan,
  Habanero,
  Jalapeno,
  Mango,
  Pineapple,
  Firmux,
  OpenWrt,
  Configuration,
  KeyConfiguration,
  Management,
  Rebranding,
  Statistics,
  Updates,
  Vpn,
  Check,
  Cloud,
  Dual,
  RealTime,
  Responsive,
  Speedtest,
  Wireguard,
  Wpa3,
  Download,
  Wifi4,
  Wifi5,
  Wifi6,
  Wifi6E,
  Logo,
];

export async function cacheImages() {
  const promises = images.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve(src);
      img.onerror = reject(src);
      document.head.appendChild(img);
    });
  });
  return await Promise.all(promises);
}
